/***---------- REGLES CSS DE BASE ----------***/
body {
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;
  font-family: 'Aller-Light';
}

#navbar{
  border-bottom: 1px solid $sm-primary-color;
}

/***---------- MAIN ----------***/

main h1, main h2, main h3{
  font-family: 'Tangerine', cursive;
  color: #142864;
  font-size: 3rem;
}

main{
  margin-top: 0rem !important;
}

section{
  padding-top: .5rem;
  padding-bottom: .5rem;
}

#section_title{
  margin-bottom: 0.4rem;
  margin-top: 0.4rem;
  border-bottom: 1px solid $sm-primary-color;
}

.badge {
  display:inline-block;
  padding:.35em .65em;
  font-size:.75em;
  font-weight:400;
  line-height:1;
  color:#fff;
  text-align:center;
  white-space:nowrap;
  vertical-align:baseline;
  border-radius:.25rem
}


/***---------- MAIN : articles ----------***/

#article_content{
  .img_overlay {
    //border: 1px solid $sm-primary-color;
    border-radius: 5px;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    background: hsla(0,0%,97%,.251);
    opacity: 0;
  }
  .img_overlay:hover {
    opacity:1;
  }
  .img_border {
    border: 1px solid $sm-primary-color;
    border-radius: 5px;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }
  .btn-card {
    border-color: #142864 !important;
    color: #142864;
    font-family: Monserrat-light;
    font-size: 1.1rem;
    transition: all .3s;
    position: relative;
    overflow: hidden;
    border-radius: 3px 3px 3px 3px;
    background-color: transparent;
    margin-bottom: .2rem;
    margin-top: 0;
  }
  .btn-card:hover {
    color:#fff;
  }
  .btn-card::after {
    content: "";
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    width: 0;
    z-index: -1;
    transition: all .3s;
    background-color: #142864;
  }
  .btn-card:hover {
    color:#fff;
  }
  .btn-change:hover::after {
    width: 100%;
  }
}

/***---------- MAIN : calligraphie ----------***/
@media (max-width:767px) {
  #calligraphie .btn {
    border-color: $sm-primary-color !important;
    color: $sm-primary-color;
    font-family: 'Monserrat-light';
    font-size: 1rem;
    transition: all 0.3s;
    position: relative;
    overflow: hidden;
    border-radius: 0px 0 0 0px;
    background-color: transparent;
    margin-bottom: 0.2rem;
    margin-top: 0;
  }
}

@media (min-width:768px) and (max-width:991px) {
  #calligraphie .btn {
    border-color: $sm-primary-color !important;
    color: $sm-primary-color;
    font-family: 'Monserrat-light';
    font-size: 0.9rem;
    transition: all 0.3s;
    position: relative;
    overflow: hidden;
    border-radius: 0px 0 0 0px;
    background-color: transparent;
    margin-bottom: 0.2rem;
    margin-top: 0;
  }
}

@media (min-width:991px) {
  #calligraphie .btn {
    border-color: $sm-primary-color !important;
    color: $sm-primary-color;
    font-family: 'Monserrat-light';
    font-size: 1.3rem;
    transition: all 0.3s;
    position: relative;
    overflow: hidden;
    border-radius: 0px 0 0 0px;
    background-color: transparent;
    margin-bottom: 0.2rem;
    margin-top: 0;
  }
}

#calligraphie .btn::before {
  content: '';
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  width: 0%;
  z-index: -1;
  transition: all 0.3s;
  background-color: #142864;
}

#calligraphie .btn:hover {
  color: #ffffff;
}

#calligraphie .btn:hover::before {
  width: 100%;
}

/***---------- MAIN : Member Card ----------***/

.card a{
  text-decoration-line: none;
}



//*** ------------- Personnalisation produit show ---------------- ***//

.op-section-product p{
  margin-bottom: 0rem;
}

#filters li{
  transition: all 0.5s;
  position: relative;
  overflow: hidden;
  background-color: transparent;
}

#filters .card > .list-group {
  border-top: none !important;
  border-bottom: none !important;
}

#filters .list-group-item {
  position: relative;
  display: block;
  padding: .5rem 1rem !important;
  color: #212529;
  text-decoration: none;
  background-color: transparent;
}

#filters li:hover {
  color: #ffffff;
  background-color: #142864;
}

/***---------- FOOTER ----------***/
footer{
  border-top:solid 1px #077dc2;
}
footer h1,
footer h2,
footer h3 {
  font-family: 'Tangerine', cursive;
  color: #222222;
  border-bottom: 1px solid #222222;
  padding-top: 1rem;
}
/***---------- FOOTER : footer-top ----------***/
#footer_top {
  background-color: #ffffff;
}

#footer_top p {
  color: #222222;
  font-size:0.8rem;
}


#footer_top a {
  color: #222222;
}

#footer_top a:hover {
  color: #333333;
}
/***---------- FOOTER : footer-bottom ----------***/
#footer_bottom {
  background-color: #077dc2;
  font-size:0.8rem;
}

#footer_bottom p {
  font-size: 12px;
  color: #ffffff;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.author:hover {
  color: #eb9c0c;
}

.author {
  color: #f56d0c;
}

.author2:hover {
  color: #eb9c0c;
}

.author2 {
  color: #DB9365;
}

.btn-primary-sm {
  color: #fff;
  background-color: #508CDC;
  border-color: #508CDC;
}

.btn-primary-sm:hover {
  color: #fff;
  background-color: #142864;
  border-color: #0a58ca;
}

#purchase-content{
  #liste{
    table{
      font-size: 0.9rem;
    }
  }
}

#navPurchase .btn-outline-sm{
  color: $sm-secondary-color;
  border-color: $sm-secondary-color !important;
  font-size: 0.8rem;
}
#navPurchase .btn-outline-sm:hover{
  background-color: $sm-secondary-color !important;
  color: #FFFFFF !important;
  border-color: $sm-secondary-color;
  font-size: 0.8rem;
}

#navPurchase .btn-outline-secondary{
  color: $sm-primary-color;
  border-color: $sm-primary-color !important;
  font-size: 0.8rem;
}
#navPurchase .btn-outline-secondary:hover{
  background-color: $sm-primary-color !important;
  color: #FFFFFF !important;
  border-color: $sm-primary-color;
  font-size: 0.8rem;
}
