/***---------- HEADER : header_home ----------***/

@media (min-width:320px) and (max-width:991px) {
  #header_home {
    background-position: center top;
    background-size: cover;
    position: relative;
    top: 0px;
    left: 0px;
    background-image: url('/public/images/home_front/fond_section_home.jpeg');
    z-index: 0;
    width: 100%;
  }
}

@media (min-width:991px) {
  #header_home {
    background-position: right top;
    background-size: cover;
    position: relative;
    top: -60px;
    left: 0px;
    background-image: url('/public/images/home_front/fond_section_home.jpeg');
    z-index: 0;
    height: 100vh;
  }
}
/***---------- HEADER : Règles concernant la Navbar ----------***/

#navbar{
  //background-color: rgba(255, 255, 255, 0.1);
  //-webkit-box-shadow: 0px 0px 10px 3px rgba(0,0,0,0.10);
  //box-shadow: 0px 0px 10px 5px rgba(0,0,0,0.19);
}

#navbar_large{
  position:relative;
  top:0;
  left:0;
  z-index:1000;
  background-color: rgba(255, 255, 255, 0.7);
  //-webkit-box-shadow: 0px 0px 10px 3px rgba(0,0,0,0.10);
  //box-shadow: 0px 0px 10px 5px rgba(0,0,0,0.19);
}

#navbar_small {
  background-color: #ffffff;
  -webkit-box-shadow: 0px 0px 10px 3px rgba(0,0,0,0.10);
  box-shadow: 0px 0px 10px 5px rgba(0,0,0,0.19);
}

header .navbar-light .navbar-nav .show > .nav-link,
header .navbar-light .navbar-nav .nav-link.active,
header .navbar-light .navbar-nav .nav-link {
  color: #142864;
  text-transform: uppercase;
}

header .navbar-light .navbar-nav .show > .nav-link,
header .navbar-light .navbar-nav .nav-link:hover {
  color: #ffffff;
  background-color: #586EB0;
  text-transform: uppercase;
}

header .dropdown-menu {
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0;
}

/***-------- HEADER : Bloc Prez --------***/
#bloc_prez {
  display: block;
  padding-top: 5%;
  padding-bottom: 10%;
}

#bloc_prez #overlay_logo {
  background-color: rgba(228, 228, 228, 0.75);
  border-radius: 5px;
  height: 8rem;
  width: 18rem;
}

/* *-------- HEADER : bloc_production --------* */
@media (max-width:767px) {
  #bloc_production3 {
    margin-bottom: 0rem;
    margin-top: 2rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  #bloc_production3 .btn-outline-smprimary {
    border-color: #ffffff !important;
    color: #ffffff;
    font-family: 'Montserrat', sans-serif;
    font-size: 1rem;
    width: 100%;
    transition: all 0.3s;
    position: relative;
    overflow: hidden;
    border-radius: 0px 0 0 0px;
    font-weight: 700;
    background-color: transparent;
    margin-bottom: 0.2rem;
    margin-top: 0;
  }
}

@media (min-width:768px) and (max-width:991px) {
  #bloc_production3 {
    margin-bottom: 0rem;
    margin-top: 2rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  #bloc_production3 .btn-outline-smprimary {
    border-color: #ffffff !important;
    color: #ffffff;
    font-family: 'Montserrat', sans-serif;
    font-size: 0.9rem;
    width: 100%;
    transition: all 0.3s;
    position: relative;
    overflow: hidden;
    border-radius: 0px 0 0 0px;
    font-weight: 700;
    background-color: transparent;
    margin-bottom: 0.2rem;
    margin-top: 0;
  }
}

@media (min-width:991px) {
  #bloc_production3 {
    margin-bottom: 2rem;
    margin-top: 15%;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 4rem;
    padding-right: 4rem;
  }

  #bloc_production3 .col-lg-3,
  #bloc_production3 .col-md-3,
  #bloc_production3 .col-sm-12
  {
    text-align: center;
  }

  #bloc_production3 .btn-outline-smprimary {
    border-color: #ffffff !important;
    color: #ffffff;
    font-family: 'Montserrat', sans-serif;
    font-size: 2rem;
    width: 100%;
    height:100%;
    transition: all 0.3s;
    position: relative;
    overflow: hidden;
    border-radius: 0px 0 0 0px;
    font-weight: 500;
    background-color: transparent;
    margin-bottom: 0.2rem;
    margin-top: 0;
    display: inline-block;
  }
}

#bloc_production3 .btn-outline-smprimary::before {
  content: '';
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  width: 0%;
  z-index: -1;
  transition: all 0.3s;
  background-color: $sm-primary-color;
}

#bloc_production3 .btn-outline-smprimary:hover {
  color: #ffffff;
}

#bloc_production3 .btn-outline-smprimary:hover::before {
  width: 100%;
}