
/*** Effets de style sur la pages des cartes ***/
#productCard{
  display: grid;
  grid-template-rows: auto auto 1fr auto;
  min-height: 350px;
  border-color: $op-primary;
  hr{
    color: $op-primary !important;
  }
  .card-meta-divider {
    width: 100%;
    margin: 0 0 1rem;
  }
  .bg-sm{
    background-color: $sm-primary-color !important;
  }
  #product-info{
    #options_evenement{
      border: 1px solid $sm-primary-color;
    }
  }
  .product_overlay{
    //border-radius: 5px;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    background: hsla(0,0%,96.9%,.251);
    opacity: 0;
  }
  .product_overlay:hover {
    opacity: 1;
  }
}

#SoeurMarie_back{
  #page-title{
    .h1_span{
      font-family: 'Monserrat-light';
      font-size: 1.5rem;
    }
  }
}

#lastproductnature{
  small {
    font-size: 1.8rem !important;
    font-family: "tangerine", cursive;
  }
  .card {
    border: none;
  }
}

#favoriesproducts{
  small {
    font-size: 1.8rem !important;
    font-family: "tangerine", cursive;
  }
  .card {
    border: none;
  }
}

.dropdown-item:hover, .dropdown-item:focus {
  color: #1e2125;
  text-decoration: none;
  background-color: #d4e0ec;
}

#productCard {
  .product-title {
    font-family: "Tangerine", cursive;
    margin-top: 0.2rem;
    color: $sm-primary-color;
    font-size: 2rem;
    line-height: 1;
  }
  .product-subtitle {
    margin-top: 0;
    margin-bottom: 0;
    color: $sm-primary-color;
    line-height: 1.2;
  }
  .btn-outline-sm{
    color: $sm-secondary-color;
    border-color: $sm-secondary-color !important;
    font-size: 0.6rem;
  }
  .btn-outline-sm:hover{
    background-color: $sm-secondary-color !important;
    color: #FFFFFF !important;
    border-color: $sm-secondary-color;
    font-size: 0.6rem;
  }
}

/*** Effets de style sur la pages des cartes - bleu léger- ***/
#Calligraphies .btn-outline-sm{
  color: $sm-secondary-color !important;
  border-color: $sm-secondary-color !important;
  font-size: 0.6rem;
}

#Calligraphies .btn-outline-sm:hover{
  background-color: $sm-secondary-color;
  color: #FFFFFF;
  border-color: $sm-secondary-color;
  font-size: 0.6rem;
}


/*** Effets de style sur la page d'un produit ***/
#productCard #count{
  padding: 0.4rem 0.2rem;
  //background-color: #ffe4c4;
}

#productCard #product-info {
  display: grid;
  grid-template-rows: auto 1fr auto;
  min-height: 416px;
}

#productCard #count .btn-outline-sm{
  color: $sm-secondary-color;
  border-color: $sm-secondary-color;
  font-size: 1rem;
}

#productCard #count .btn-outline-sm:hover{
  background-color: $sm-secondary-color;
  color: #FFFFFF;
  border-color: $sm-secondary-color;
  font-size: 1rem;
}

#productCard #count p{
  font-size: 0.8rem;
}

.header__breadcrumb-item:first-child {
  margin-left: 0;
}

.header__breadcrumb-item--active {
  background: #989898;
}

.header__breadcrumb-item {
  background: #d8d8d8;
  border-radius: 100%;
  color: #fff;
  display: inline-block;
  font-size: 12px;
  height: 20px;
  line-height: 20px;
  margin-left: 40px;
  position: relative;
  text-align: center;
  width: 20px;
  z-index: 1;
}
.header__breadcrumb-item a {
  color: #fff;
}
.header__breadcrumb-label {
  display: none;
}
.header__breadcrumb::before {
  background: #d8d8d8;
  content: "";
  height: 1px;
  left: 0;
  position: absolute;
  right: 0;
  top: 12px;
  z-index: 0;
}
.header__breadcrumb::after {
  display: none;
}
@media (min-width:1024px) {
  .header__breadcrumb {
    float: none;
    left: 50%;
    margin: 5px auto 0 -150px;
    position: absolute;
    width: 300px;
  }
  .header__breadcrumb::after {
    background: #989898;
    content: "";
    display: block;
    height: 1px;
    left: 0;
    position: absolute;
    right: 70%;
    top: 15px;
    z-index: 0;
  }
  .header__breadcrumb--short::before {
    width: 60%;
  }
  .header__breadcrumb::before {
    left: 10px;
    top: 15px;
  }
  .header__breadcrumb-item:first-child {
    margin-left: 0;
  }
  .header__breadcrumb--basket .header__breadcrumb-item--short {
    margin-left: calc(50% - 10px);
  }
  .header__breadcrumb--basket .header__breadcrumb-item {
    margin-left: calc(50% - 45px);
  }
  .header__breadcrumb-item--short {
    margin-left: calc(35% - 45px);
  }
  .header__breadcrumb-item {
    height: 30px;
    line-height: 30px;
    margin-left: calc(35% - 45px);
    width: 30px;
  }
  .header__breadcrumb-label {
    color: #989898;
    display: block;
    left: 50%;
    margin-left: -75px;
    padding-top: 30px;
    position: absolute;
    top: 0;
    width: 150px;
  }
}
